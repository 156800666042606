import * as Base from '../../../components/base';
import * as Styled from './styled';

import React, { useCallback } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

const SectionFour = ({ showContactModal }) => {
  const intl = useIntl();

  const handleContactButtonClick = useCallback(() => {
    showContactModal();
  });

  return (
    <StaticQuery
      query={graphql`
        query {
          bg: file(relativePath: { eq: "4_bg.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          nate: file(relativePath: { eq: "nate.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <Styled.BgContainer imageData={data.bg.childImageSharp.fluid}>
          <Styled.Wrapper>
            <Styled.Container>
              <Styled.SvgContainer>
                <Base.No4 />
              </Styled.SvgContainer>
              <Styled.HeadingBlock>
                <Base.Title1>{intl.formatMessage({ id: 'sectionFour.heading' })}</Base.Title1>
              </Styled.HeadingBlock>
              <Styled.SvgLine />
              <Styled.Text1>
                <Styled.Forester>{intl.formatMessage({ id: 'sectionFour.forester' })}</Styled.Forester>
                <Styled.Pron>{intl.formatMessage({ id: 'sectionFour.pron' })}</Styled.Pron>
                <Styled.Noun>{intl.formatMessage({ id: 'sectionFour.noun' })}</Styled.Noun>
                <Styled.Definition>{intl.formatMessage({ id: 'sectionFour.definition' })}</Styled.Definition>
              </Styled.Text1>
              <Styled.Description>
                <Styled.Title2>{intl.formatMessage({ id: 'sectionFour.description' })}</Styled.Title2>
              </Styled.Description>
              <Styled.ButtonBlock>
                <Styled.Button onClick={handleContactButtonClick}>
                  {intl.formatMessage({ id: 'letsPlay' })}
                </Styled.Button>
              </Styled.ButtonBlock>
            </Styled.Container>
            <Styled.NateContainer imageData={data.nate.childImageSharp.fluid}></Styled.NateContainer>
          </Styled.Wrapper>
        </Styled.BgContainer>
      )}
    />
  );
};

export default SectionFour;
